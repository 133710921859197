<template>
  <div class="warehouse">
    <category-title :category="category" />

    <router-view></router-view>
    <faq-bar v-if="isAuthenticated" />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import category from "~/mixins/category.js";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: {
    CategoryTitle
  },
  mixins: [category],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  }
};
</script>
